import React from "react";

function News() {
  return (
    <div className="news-container"> 
      <div>
        <p className="home-subtitle">BASINDA BİZ</p>
      </div>
      <div className="gridContainer">
        <div className="grid-item">1</div>
        <div className="grid-item">2</div>
        <div className="grid-item">3</div>
        <div className="grid-item">4</div>
      </div>
      <div className="gridContainer">
        <div className="grid-item">5</div>
        <div className="grid-item">6</div>
        <div className="grid-item">7</div>
        <div className="grid-item">8</div>
      </div>
    </div>
  );
}

export default News;
