import appIdea from '../../components/images/appIdea.jpg'

function AboutUs() {
  return (
    <div className="aboutus-container">
      <div className="aboutus-body">
        <div className="text-container">
          <p className="aboutus-subtitle">FİKRİNİZ Mİ VAR?</p>
          <p className="aboutus-text">
              Analysis Software Company LTD. olarak fikirlerinizi önemsiyoruz. Gelin birlikte geliştirelim, yazalım ve pazarlayalım.
          </p>
          <p className="aboutus-text">
              Kuluçka merkezlerindeki melek yatırımcılara projenizi sunalım, beraber hayata geçirelim. Fikirleriniz için bizimle ileşime geçin.
          </p>
        </div>
        <img src={appIdea} width={"60%"} height={"60%"} alt="" />
      </div>
    </div>
  );
}

export default AboutUs;
