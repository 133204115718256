import { Link, Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import appinfoimg from "../components/images/appinfo.png";
import downloadnow from "../components/images/downloadnow.png";
import companyLogo from "../components/images/company-logo.png";

function SharedLayout() {
  return (
    <div className="shared-container">
      <div className="mobile-logo">
        <img
          className="logo"
          src={companyLogo}
          width={"35%"}
          height={"35%"}
          alt=""
        />
        <Navbar />
      </div>
      <Link to="home" />
      <div className="appinfo">
        <div className="imageContainer">
          <img src={appinfoimg} width={"95%"} height={"100%"} alt="" />
        </div>
        <div className="downloadnow">
          <img src={downloadnow} width={"100%"} height={"100%"} alt="" />
          <a
            href="https://www.google.com/"
            className="appstorelink"
            role="link"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            href="https://www.google.com/"
            className="googleplaylink"
            role="link"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            href="https://www.google.com/"
            className="downloadnowlink"
            role="link"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div>
      </div>
      <div id="outlet">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default SharedLayout;
