import React from "react";
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsYoutube,
  BsTelegram,
} from "react-icons/bs";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-socialmedia-container">
        <div className="footer-socialmedia">
          <a
            href="https://www.facebook.com/goalradarapp/"
            role="link"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook fill="#FFFFFF" size={"4vw"} />
          </a>
          <a href="#" role="link">
            <BsTelegram fill="#FFFFFF" size={"4vw"} />
          </a>
          <a
            href="https://mobile.twitter.com/goalradarapp"
            role="link"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter fill="#FFFFFF" size={"4vw"} />
          </a>
          <a
            href="https://www.instagram.com/goalradarapp/"
            role="link"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram fill="#FFFFFF" size={"4vw"} />
          </a>
          <a
            href="https://www.youtube.com/@goalradar6633/featured"
            role="link"
            target="_blank"
            rel="noreferrer"
          >
            <BsYoutube fill="#FFFFFF" size={"4vw"} />
          </a>
        </div>
      </div>
      <div className="footer-info-container">
        <div className="footer-info-text">
          Goal Radar&#8482; markası ‘Analysis Software Company LTD.’
          kuruluşudur.
        </div>
        <hr />
        <div className="footer-info-copyright">
          &copy;2022 Analysis Software Company LTD. Tüm Hakları Saklıdır. |
          Copyright &copy;2022 Analysis Software Company LTD. All Right
          Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
