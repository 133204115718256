import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

function Navbar() {
  const [menuSidebar, setMenuSidebar] = React.useState(false);
  const showMenuSidebar = () => setMenuSidebar(!menuSidebar);

  return (
    <div className="navbar-container">
      <div className="navbar-classic">
        <div className="navbar-items">
          <ul>
            <NavLink to="home" className="navbar-link">
              <li>Biz Kimiz?</li>
            </NavLink>
            <NavLink to="mobileapp" className="navbar-link">
              <li>Goal Radar Nedir?</li>
            </NavLink>
            <NavLink to="aboutus" className="navbar-link">
              <li>Fikriniz mi Var?</li>
            </NavLink>
            <a href="http://goalradar.com.tr/" target="_blank" className="navbar-link">
              <li>Goal Radar'a Git</li>
            </a>
            <NavLink to="contact" className="navbar-link">
              <li>Bize Ulaşın</li>
            </NavLink>
          </ul>
        </div>
      </div>
      <div className="navbar-mobile">
        <div className="menu-overlay">
          <FaBars className="menu-bars" onClick={showMenuSidebar} />
          <div className={menuSidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items">
              <NavLink to="home" onClick={showMenuSidebar}>
                <li className="nav-text">Biz Kimiz?</li>
              </NavLink>
              <NavLink to="mobileapp" onClick={showMenuSidebar}>
                <li className="nav-text">Goal Radar Nedir?</li>
              </NavLink>
              <NavLink to="aboutus" onClick={showMenuSidebar}>
                <li className="nav-text">Fikriniz mi Var?</li>
              </NavLink>
              <a href="http://goalradar.com.tr/" target="_blank" className="navbar-link">
                <li className="nav-text">Goal Radar'a Git</li>
              </a>
              <NavLink to="contact" onClick={showMenuSidebar}>
                <li className="nav-text">Bize Ulaşın</li>
              </NavLink>
              <IoMdClose className="navbar-toggle" onClick={showMenuSidebar} />
            </ul>
          </div>
          <div
            className={
              menuSidebar ? "menu-overlay-effect active" : "menu-overlay-effect"
            }
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
